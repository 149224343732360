<section class="grid grid-rows-1 justify-items-center items-center">
  <header>
    <ng-container >
      <h3 class="m-0">{{confirmationDialogDataTitulo}}</h3>
      </ng-container>
  </header>

  <article class="text-center mt-1" *ngIf="showForm">
      <form [formGroup]="dataForm">
        <section class="grid grid-flow-row w-[20%]" >
          <div class="flex gap-4 items-baseline">
            
            <div class="divform">
              <mat-form-field id="nombre" appearance="outline">
                <mat-label class="text-sm font-medium text-gray-900">Nombre:</mat-label>
                <input matInput formControlName="nombre" />
                 <mat-error *ngIf="dataForm.get('nombre')?.hasError('required')"
                  >{{ constants.CAMPO_REQUERIDO }}
                </mat-error>
                
              </mat-form-field>
            </div>
          </div>
          <div class="flex gap-4 items-baseline">
           
            <div class="divform">
              <mat-form-field id="estado" appearance="outline">
                <mat-label class="text-sm font-medium text-gray-900">Estado:</mat-label>
                <mat-select formControlName="estado">
                  <mat-option
                    *ngFor="let estado of estados"
                    [value]="estado.codigo"
                  >
                    {{ estado.nombre }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="flex gap-4 items-baseline">
          
            <div class="divform">
              <mat-form-field id="color" appearance="outline">
                <mat-label class="text-sm font-medium text-gray-900">Color:</mat-label>
                <mat-select formControlName="color" >
                  <mat-option
                    *ngFor="let color of colors"
                    [value]="color"
                    (click)="selectColor(color)"
                  >
                  <div class="paletecolors" [style.background-color]="color"></div>
                  <div class="paletecolorvalue">{{color}}</div> 
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </section>
      </form>
  </article>

  <div class="flex justify-center gap-4">
    <div *ngIf="confirmationDialogData.dialogTextConfirmar">
      <button
        mat-raised-button
        (click)="confirmarAccionDialog()"
        class="buttonpredeterminado"
      >
        <span class="text-white">
          {{ confirmationDialogData.dialogTextConfirmar }}
        </span>
      </button>
    </div>
    <div *ngIf="confirmationDialogData.dialogTextCancelar">
      <button
        class="ml-4"
        color="warn"
        mat-raised-button
        (click)="cerrarDialog()"
      >
        <span class="text-white">
          {{ confirmationDialogData.dialogTextCancelar }}
        </span>
      </button>
    </div>
  </div>
</section>
