import { Pipe, PipeTransform } from '@angular/core';
import { es } from 'date-fns/locale';
import {
  FLAG_ESTADO,
  FLAG_YES_NO,
  PIPE_FLAG_TRANSFORMACION,
  FLAG_VITAMINA_MINERAL,
  FLAG_EMPRESA_LOCAL,
} from '../constants/user-interface/user-interface-messages.constants';

@Pipe({
  name: 'BooleanToString',
})
export class BooleanToStringPipe implements PipeTransform {
  transform(value: unknown, criteria: string): string {
    switch (criteria) {
      case PIPE_FLAG_TRANSFORMACION.activo_inactivo:
        return value ? FLAG_ESTADO.ACTIVO : FLAG_ESTADO.INACTIVO;
      case PIPE_FLAG_TRANSFORMACION.yes_no:
        if (typeof value === 'boolean') {
          return value ? FLAG_YES_NO.SI : FLAG_YES_NO.NO;
        }
        return value as string;
      case PIPE_FLAG_TRANSFORMACION.vitamina_mineral:
          if (typeof value === 'boolean') {
            return value ? FLAG_VITAMINA_MINERAL.VITAMINA : FLAG_VITAMINA_MINERAL.MINERAL;
          }
      return value as string;
          case PIPE_FLAG_TRANSFORMACION.empresa_local:
          if (typeof value === 'boolean') {
            return value ? FLAG_EMPRESA_LOCAL.EMPRESA : FLAG_EMPRESA_LOCAL.LOCAL;
          }
          return value as string;
      default:
        return 'Pipe Not Defined';
    }
  }
}
