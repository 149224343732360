import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, delay, finalize, map } from 'rxjs/operators';
import { STATUS_NOTIFICATION } from '../constants/user-interface/status-notification.constants';
import { ConfirmationDialogData } from '../constants/user-interface/user-interface-messages.types';
import { GlobalSpinnerService } from '../content/shared/global-spinner/global-spinner.service';
import { NotificationDialogService } from '../content/shared/notification-dialog/notification-dialog.service';
import { ResponseBackend } from '../models/http-client/backend-response.model';

@Injectable()
export class HttpHandlerInterceptor implements HttpInterceptor {
  constructor(
    private notificationDialogService: NotificationDialogService,
    private router: Router,
    private globalSpinnerService: GlobalSpinnerService
  ) {}

  timerId = 0;

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
    this.timerId = setTimeout(
      () => this.globalSpinnerService.showSpinner(),
      500
    );
    return next.handle(request).pipe(
      map((event: HttpEvent<ResponseBackend<unknown>>) => {
        return event;
      }),
      finalize(() => {
        this.globalSpinnerService.hideSpinner();
        if (this.timerId) {
          clearTimeout(this.timerId);
        }
      }),
      catchError((errorHttp: HttpErrorResponse) => {
        let errorMessage: any;
        if (errorHttp.error instanceof ErrorEvent) {
          // Error Client Side, may be used for debugging purposes if needed
          errorMessage = errorHttp.error.message;
        } else {
          errorMessage = errorHttp.error.mensajes
            ? errorHttp.error.mensajes[0]
            : STATUS_NOTIFICATION.ERROR_DESCONOCIDO;
          switch (errorHttp.status) {
            case 403:
              this.router.navigate([`error`], {
                queryParams: { errorMessage: 'Error 403 - Forbidden' },
              });
              break;
            case 404:
              this.router.navigate([`error`], {
                queryParams: { errorMessage: 'Error 404 - Not Found' },
              });
              break;
            case 400:
              const dialogConfiguration: ConfirmationDialogData = {
                dialogType: 'ERROR',
                dialogMessage: errorMessage.message?errorMessage.message:errorMessage,
                dialogTextConfirmar: 'Aceptar',
              };
              this.notificationDialogService.abrir(dialogConfiguration);
              break;
            default:
              this.router.navigate([`error`], {
                queryParams: {
                  errorHttpMessage: STATUS_NOTIFICATION.ERROR_DESCONOCIDO,
                },
              });
              break;
          }
        }
        return throwError(() => {
          throw new Error(errorMessage);
        });
      })
    );
  }
}
