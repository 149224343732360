export interface TopNavigationBarMenu {
  readonly name: string;
  readonly route?: string;
  readonly icon?: string;
  readonly subMenus?: TopNavigationBarSubMenu[] | null;
}

export interface TopNavigationBarSubMenu {
  readonly name: string;
  readonly route?: string;
  readonly icon?: string;
  readonly subMenus?: TopNavigationBarMenu[] | null;
}

export const TOP_NAVIGATION_BAR_ITEMS: TopNavigationBarMenu[] = [
  {
    name: 'Mantenimientos',
    route: '',
    subMenus: [
      {
        name: 'Básicos',
        route: '',
        subMenus: [
          {
            name: 'Países',
            route: 'MOVIMIENTOS',
            icon: '',
            subMenus: null,
          },
          {
            name: 'Nidos',
            route: 'Nidos',
            icon: '',
            subMenus: null,
          },
          {
            name: 'Localidades',
            route: 'localidades',
            icon: '',
            subMenus: null,
          },
          {
            name: 'Tipos de documento',
            route: 'tipos-documento',
            icon: '',
            subMenus: null,
          },
          {
            name: 'Tipos de trámite',
            route: 'tipos-tramite',
            icon: '',
            subMenus: null,
          },
          {
            name: 'Títulos',
            route: 'titulos',
            icon: '',
            subMenus: null,
          },
          {
            name: 'Procedimientos de conservación',
            route: 'procedimientos-conservacion',
            icon: '',
            subMenus: null,
          },
          {
            name: 'Materiales',
            route: 'materiales',
            icon: '',
            subMenus: null,
          },
          {
            name: 'Marcas',
            route: 'marcas',
            icon: '',
            subMenus: null,
          },
          {
            name: 'Configuraciones',
            route: 'configuraciones',
            icon: '',
            subMenus: null,
          },
        ],
      },
      {
        name: 'Empresa',
        route: '',
        subMenus: [
          {
            name: 'Tipos de empresa',
            route: 'tipos-empresa',
            subMenus: null,
            icon: '',
          },
          {
            name: 'Categorías',
            route: 'categorias-empresa',
            icon: '',
            subMenus: null,
          },
          {
            name: 'Rubros',
            route: 'rubros',
            icon: '',
            subMenus: null,
          },
          {
            name: 'Giros',
            route: 'giros',
            icon: '',
            subMenus: null,
          },
        ],
      },
      {
        name: 'Locales',
        route: '',
        subMenus: [
          {
            name: 'Clases',
            route: 'clases',
            icon: '',
            subMenus: null,
          },
          {
            name: 'SubClases ',
            route: 'subclases',
            icon: '',
            subMenus: null,
          },
          // {
          //   name: 'Giros de locales',
          //   route: 'giros-locales',
          //   subMenus: null,
          //   icon: 'bug_report',
          // },
        ],
      },
      {
        name: 'Vehículo',
        route: '',
        subMenus: [
          {
            name: 'Tipos de vehículo',
            route: 'tipos-vehiculos',
            subMenus: null,
            icon: '',
          },
          {
            name: 'Giros de vehículo',
            route: 'giros-vehiculos',
            subMenus: null,
            icon: '',
          },
          {
            name: 'Categorías de vehículo',
            route: 'categorias-vehiculos',
            subMenus: null,
            icon: '',
          },
        ],
      },
      {
        name: 'Producto',
        route: '',
        subMenus: [
          {
            name: 'Productos',
            route: 'productos',
            subMenus: null,
            icon: '',
          },
          {
            name: 'Capítulos de producto',
            route: 'capitulos-producto',
            subMenus: null,
            icon: '',
          },
          {
            name: 'Secciones de producto',
            route: 'secciones-producto',
            subMenus: null,
            icon: '',
          },
          {
            name: 'Sub Secciones de producto',
            route: 'subsecciones-producto',
            subMenus: null,
            icon: '',
          },
          // {
          //   name: 'Denominación de venta de producto',
          //   route: 'denominacion-venta-producto',
          //   icon: 'bug_report',
          //   subMenus: null,
          // },
          {
            name: 'Unidades de productos',
            route: 'unidades-productos',
            icon: '',
            subMenus: null,
          },
        ],
      },
      {
        name: 'Otros',
        route: '',
        subMenus: [
          {
            name: 'Condiones de almacén',
            route: 'condiciones-almacen',
            icon: '',
            subMenus: null,
          },
          {
            name: 'Ingredientes',
            route: 'ingredientes',
            icon: '',
            subMenus: null,
          },
          {
            name: 'Líneas de producción',
            route: 'lineas-produccion',
            icon: '',
            subMenus: null,
          },
          {
            name: 'Líneas de producción de vehículos',
            route: 'lineas-produccion-vehiculo',
            icon: '',
            subMenus: null,
          },
          // {
          //   name: 'Coadyuvantes',
          //   route: 'coadyuvantes',
          //   icon: 'bug_report',
          //   subMenus: null,
          // },
          {
            name: 'Vitaminas y Minerales',
            route: 'vitaminas-minerales',
            icon: '',
            subMenus: null,
          },
        ]
      },
      {
        name: 'Aditivos',
        route: '',
        subMenus: [
          {
            name: 'Aditivos',
            route: 'aditivos',
            subMenus: null,
            icon: '',
          },
          {
            name: 'Funciones de aditivo',
            route: 'funciones-aditivos',
            subMenus: null,
            icon: '',
          },
          // {
          //   name: 'Combinaciones de aditivos y sus funciones',
          //   route: 'combinaciones-aditivos-funciones',
          //   icon: 'bug_report',
          //   subMenus: null,
          // },
          {
            name: 'Límites de aditivos',
            route: 'limite-maximo-aditivos',
            subMenus: null,
            icon: '',
          },
        ],
      },
      {
        name: 'Envase',
        route: '',
        subMenus: [
          {
            name: 'Tipos de envase',
            route: 'tipos-envase',
            icon: '',
            subMenus: null,
          },
          {
            name: 'Tipos de cierres de envase',
            route: 'tipos-cierre-envase',
            icon: '',
            subMenus: null,
          },
        ],
      },
    ],
  },
];
