export const RUNAEV_MATERIAL_DATE_FORMAT = {
    parse: {
      dateInput: 'dd/MM/yyyy',
    },
    display: {
      dateInput: 'dd/MM/yyyy',
      monthYearLabel: 'MMM yyyy',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM yyyy',
    },
};
