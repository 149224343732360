import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalSpinnerService {

  isLoading = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoading.asObservable();

  constructor() { }

  showSpinner(): void {
    this.isLoading.next(true);
  }

  hideSpinner(): void {
    this.isLoading.next(false);
  }
}
