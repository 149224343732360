import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogData } from 'src/app/constants/user-interface/user-interface-messages.types';

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss'],
})
export class NotificationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<NotificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public confirmationDialogData: ConfirmationDialogData
  ) {}

  cerrarDialog(): void {
    this.dialogRef.close(false);
  }

  confirmarAccionDialog(): void {
    this.dialogRef.close(true);
  }

  get confirmationDialogDataType(): string {
    return this.confirmationDialogData.dialogType;
 }
}
