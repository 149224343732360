import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FiltroOrdenAtributo } from 'src/app/models/filtros/filtro-orden-atributo.model';
import { Filtro } from 'src/app/models/filtros/filtro.model';
import { ResponseBackend } from 'src/app/models/http-client/backend-response.model';
import { CustomHttpClientService } from 'src/app/services/http-web-client/custom-http-client.service';
import { FilterUtils } from 'src/app/utils/filter-utils';


declare interface SelectAutocompleteFilter {
  value: SelectAutocompleteFilterValue,
  page: number,
  length: number
}

declare type SelectAutocompleteFilterValue = {
  atributo: Record<string, boolean | string | number>;
}

export type SelectAutocompleteEntity = {
  searchField: string;
  id: number;
  codigo: string;
  nombre: string;
}


@Injectable({
  providedIn: 'root',
})
export class SelectLazyAutocompleteService<T> {
  apiUrl: string = '';
  selectObjectFilter!: SelectAutocompleteEntity;
  private objectFilter = new BehaviorSubject<SelectAutocompleteEntity>(this.selectObjectFilter);
  constructor(private customHttpClientService: CustomHttpClientService<T>) {}

  filtrarEntidad(filtros: Filtro): Observable<ResponseBackend<T[]>> {
    this.agregarOrdenYPaginadoAlFiltroEntidad(filtros); 
    return this.customHttpClientService.filtrar(this.apiUrl, filtros);
  }

  agregarOrdenYPaginadoAlFiltroEntidad(filtros: Filtro): void {
    filtros
      .agregarPaginado(filtros.pagina, filtros.registrosPorPaginas)
      .agregarOrden(new FiltroOrdenAtributo());
  }
  
  crearFiltroEntidad(data: SelectAutocompleteFilter): Filtro {
    return FilterUtils.crearFiltro(data.value.atributo, data.page, data.length);
  }

  defineChildtoFilter(value: SelectAutocompleteEntity): void {
    this.objectFilter.next(value);
  }
  getChildtoFilter(): BehaviorSubject<SelectAutocompleteEntity> {
    return this.objectFilter;
  }
}
