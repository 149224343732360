<div
  class="
    flex
    items-center
    justify-center
    w-screen
    h-screen
    bg-blue-900
  "
>
  <div class="px-40 py-20 bg-white rounded-md shadow-xl">
    <div class="flex flex-col items-center">
      <p class="font-bold text-blue-600 text-[40px]">404</p>

      <h2 class="mb-2 text-2xl font-bold text-center text-gray-800 md:text-3xl">
        <span class="text-red-500">Oops!</span> Página no encontrada
      </h2>

      <p class="mb-8 text-center text-gray-500 md:text-lg">
        La página que está buscando no existe.
      </p>

      <a href=""
        class="px-6 py-2 text-sm font-semibold text-blue-800 bg-blue-100"
        >Ir a inicio</a
      >
    </div>
  </div>
</div>
