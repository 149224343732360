<mat-sidenav-container class="relative left-0 right-0 h-full">
  <mat-sidenav
    #sidenav
    mode="side"
    opened
    class="flex items-center justify-center w-56 bg-slate-300"
    [fixedInViewport]="false"
  >
    <app-left-sidebar-navigation-tree></app-left-sidebar-navigation-tree>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="m-6">
      <xng-breadcrumb separator="/"> </xng-breadcrumb>
    </div>
    <!-- <app-notificaciones></app-notificaciones> -->
    <main>
      <router-outlet></router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
