export const API_URL_DEVELOPMENT = {
    MANTENIMIENTOS_BASICOS: {
      MOVIMIENTOS: '/pais',
      Nidos: '/departamento',
      TIPOS_DE_DOCUMENTOS: '/tipo-documento',
      CATEGORIAS_EMPRESA: '/categoria',
      CLASES: '/clase',
      TIPOS_DE_VEHICULO: '/tipos-vehiculos',
      CATEGORIAS_DE_VEHICULO: '/categoria-vehiculo',
      UNIDADES_DE_PRODUCTOS: '/unidad',
      PROCEDIMIENTOS_DE_CONSERVACION: '/procedimiento-conservacion',
      INGREDIENTES: '/ingrediente',
      TIPOS_DE_CIERRE_DE_ENVASE:'/tipo-cierre',
      COADYUVANTES: '/coadyuvantes',
      ADITIVOS: '/aditivo',
      MATERIALES: '/material',
      CONDICIONES_DE_ALMACEN: '/condicion-almacen',
      FUNCIONES_ADITIVOS: '/funcion-aditivo',
      TIPOS_DE_ENVASES: '/tipo-envase',
      TIPOS_DE_EMPRESAS: '/tipo-empresa',
      GIROS_DE_VEHICULOS: '/giros-vehiculos',
      CAPITULOS_DE_PRODUCTO: '/capitulo',
      GIROS: '/giro-empresa',
      MANTENIMIENTO_TITULOS: '/titulo',
      SUBCLASES: '/sub-clase',
      LINEAS_DE_PRODUCCION: '/linea-produccion',
      LINEAS_DE_PRODUCCION_VEHICULO: '/linea-produccion-vehiculo',
      TIPOS_DE_TRAMITE: '/tipo-tramite',
      SECCIONES_DE_PRODUCTOS: '/seccion',
      COMINACIONES_DE_ADITIVOS_Y_FUNIONES: '/combinacion-aditivos-funciones',
      LIMITES_DE_ADITIVOS: '/limite-maximo-aditivos',
      GIROS_DE_LOCALES:'/giros-locales',
      SUBSECCIONES_DE_PRODUCTOS:'/sub-seccion',
      DENOMINACION_DE_VENTA_DE_PRODUCTO:'/denominacion-productos',
      PRODUCTOS:'/producto',
      VITAMINA_Y_MINERALES: '/vitamina-mineral',
      MARCAS: '/marca',
      RUBROS: '/rubro',
      SERVICE_PARAMS_IDURUGUAY: 'api/v1/findInfoForCodeRequest',
      SERVICE_GETTOKEN_IDRUGUAY: 'api/v1//findByInfoUser',
      LOCALIDADES:'/localidad',
      CONFIGURACIONES: '/configuracion'
    },
  };
  