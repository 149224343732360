<header class="flex flex-wrap justify-between items-center" style="background-color: white">
  <div id="header_logo" class="my-3 mx-2">
    <img alt="logo" src="assets/images/logo/imagen.png" width="80px" style="margin-left: 20px;" routerLink="consolidate/global">
  </div>
  <div id="header_logo" class="my-3 mx-2">
    <div class="containersaldo">
      <div class="centersaldo">
      <label class="labelsaldo"> Saldo</label>
    </div>
    
      <div class="centersaldo">
        <form formMantenimientoBasicoContent [formGroup]="dataForm">
        <mat-form-field id="saldo" appearance="outline" class="fieldsaldo">
          <span matPrefix>Euros</span>
          <input matInput  formControlName="saldo" readonly="true"/>
        </mat-form-field>
      </form>
       </div>

   </div>
    
  </div>
  <div id="header_icons" class="flex my-3 mx-2" style="margin-right: 7.5rem;">
    <button id="header_notification_button" class="inline-flex relative items-center p-3 mr-1 text-sm font-medium text-center text-white rounded" style="color: rgb(255 255 255 / var(--tw-text-opacity));">
      <div class="mr-2">
        <img alt="notification-bell" src="assets/images/icons/bell.png" width="35px" height="25px">
      </div>
      <div class="inline-flex absolute top-1 -right-0.5 justify-center items-center w-6 h-6 text-xs text-white rounded-full border-2 border-white mr-2" style="background-color: rgb(32,38,70);">
        <span>20</span>
      </div>
    </button>
    <div id="header_icon_separator" class=" relative border-l-[1px] border-l-slate-800 border-r-[1px] border-r-slate-800 h-8 top-2" style="background-color: rgb(32,38,70);">
    </div>
    <button id="header_user-session_button" [matMenuTriggerFor]="user_session_menu">
      <div class="flex justify-center items-center ml-2 top-1">
        <img alt="user-session" src="assets/images/icons/user.png" width="30px" height="30px">
        <span class="p-1">Isis Guillot</span>
        <div class="border-l-[6px] border-r-[6px] border-t-[6px] border-l-solid border-r-solid border-t-solid border-l-transparent border-r-transparent border-gray-900"></div>
      </div>
    </button>
  </div>
  <mat-menu #user_session_menu="matMenu">
    <button mat-menu-item>
      <span>Cerrar sesión</span>
    </button>
  </mat-menu>

</header>