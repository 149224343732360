import { ConfirmationDialogData } from "./user-interface-messages.types";

export const MENSAJES_TABLA_VACIA = {
  MANTENIMIENTOS_BASICOS: {
    MOVIMIENTOS: 'No existen movimientos para mostrar',
    NIDOS: 'No existen Nidos registrados',
    TIPOS_DE_DOCUMENTO: 'No existen tipos de documento registrados',
    CATEGORIAS_EMPRESAS: 'No existen categorías registrados',
    CLASES: 'No existen Clases  registrados',
    TIPOS_DE_VEHICULOS: 'No existen tipos de vehículos registrados',
    CATEGORIAS_DE_VEHICULOS: 'No existen categorías de vehículos registrados',
    UNIDADES_DE_PRODUCTOS: 'No existen unidades de productos registrados',
    PROCEDIMIENTOS_DE_CONSERVACION:'No existen proceedimientos de conservación registrados',
    INGREDIENTES: 'No existen ingredientes registrados',
    COADYUVANTES: 'No existen coadyuvantes registrados',
    MATERIALES:'No existen materiales registrados',
    CONDICIONES_DE_ALMACEN: 'No existen condiciones de almacén registrados',
    ADITIVOS: 'No existen aditivos registrados',
    TIPOS_DE_CIERRE_DE_ENVASE:'No existen tipos de cierres de envases de registrados',
    FUNCIONES_ADITIVOS: 'No existen funciones de aditivos registrados',
    TIPOS_DE_ENVASES: 'No existen tipos de envases registrados',
    TIPOS_DE_EMPRESAS: 'No existen tipos de empresas registrados',
    GIROS_DE_VEHICULOS: 'No existen giros de vehículos registrados',
    CAPITULOS_DE_PRODUCTO: 'No existen capítulos de productos registrados',
    GIROS: 'No existen giros registrados',
    TITULOS: 'No existen titulos registrados',
    SUBCLASES: 'No existen Clases  registrados',
    LINEAS_DE_PRODUCCION:'No existen líneas de producción registradas',
    LINEAS_DE_PRODUCCION_VEHICULO: 'No existen líneas de producción de vehículos registradas',
    SECCIONES_DE_PRODUCTOS: 'No existen secciones de productos registradas',
    COMINACIONES_DE_ADITIVOS_Y_FUNIONES: 'No existen combinaciones de aditivos y sus funciones registradas',
    LIMITES_DE_ADITIVOS:'No existen límites de aditivos registrados',
    GIROS_DE_LOCALES:'No existen giros de locales registrados',
    SUBSECCIONES_DE_PRODUCTOS:'No existen sub secciones de productos registrados',
    DENOMINACION_DE_VENTA_DE_PRODUCTO:'No existen denominaciones de ventas de productos registrados',
    PRODUCTOS:'No existen productos registradas',
    TIPOS_DE_TRAMITE: 'No existen tipos de trámite registrados',
    NO_ELEMENTOS_SELECCIONADOS: 'No hay elementos seleccionados',
    DOCUMENTOS_OBLIGATORIOS_SELECCIONADOS: 'No hay documentos seleccionados',
    VITAMINA_Y_MINERALES:'No existen vitaminas y minerales registrados',
    MARCAS: 'No existen marcas registradas',
    RUBROS: 'No existen rubros registrados',
    LOCALIDADES:'No existen localidades registradas',
    CONFIGURACIONES:'No existen configuraciones registradas'
  },
};

export const NOMBRES_ENTIDADES_NEGOCIO = {
  MANTENIMIENTOS_BASICOS: {
    MOVIMIENTOS: 'Movimientos',
    NIDOS: 'Nidos',
    TIPOS_DE_DOCUMENTO: 'Tipos de Documento',
    CATEGORIAS_EMPRESAS: 'Categorías',
    CLASES: 'Clases ',
    TIPOS_DE_VEHICULOS: 'Tipos de Vehículos',
    CATEGORIAS_DE_VEHICULOS: 'Categorías de Vehículos',
    UNIDADES_DE_PRODUCTOS: 'Unidades de Productos',
    PROCEDIMIENTOS_DE_CONSERVACION:'Procedimientos de Conservación',
    INGREDIENTES: 'Ingredientes',
    TIPOS_DE_CIERRE_DE_ENVASE:'Tipos de Cierres de Envase',
    COADYUVANTES: 'Coadyuvantes',
    MATERIALES:'Materiales',
    CONDICIONES_DE_ALMACEN: 'Condiciones de Almacén',
    ADITIVOS: 'Aditivos',
    FUNCIONES_ADITIVOS: 'Funciones de aditivos',
    TIPOS_DE_ENVASES: 'Tipos de Envases',
    TIPOS_DE_EMPRESAS: 'Tipos de Empresas',
    GIROS_DE_VEHICULOS: 'Giros de Vehículos',
    CAPITULOS_DE_PRODUCTO: 'Capítulos de Producto',
    GIROS: 'Giros',
    TITULOS: 'Titulos',
    SUBCLASES: 'SubClases ',
    LINEAS_DE_PRODUCCION: 'Líneas de Producción',
    LINEAS_DE_PRODUCCION_VEHICULO: 'Líneas de Producción de Vehículos',
    SECCIONES_DE_PRODUCTOS: 'Secciones de Productos',
    COMINACIONES_DE_ADITIVOS_Y_FUNIONES: 'Combinaciones de Aditivos y sus Funciones',
    LIMITES_DE_ADITIVOS:'Límites de Aditivos',
    GIROS_DE_LOCALES:'Giros de Locales',
    SUBSECCIONES_DE_PRODUCTOS:'Sub Secciones de Productos',
    DENOMINACION_DE_VENTA_DE_PRODUCTO:'Denominaciones de Ventas de Productos',
    PRODUCTOS:'Productos',
    TIPOS_DE_TRAMITE: 'Tipos de Tramite',
    VITAMINA_Y_MINERALES:'Vitaminas y Minerales',
    MARCAS: 'Marcas',
    RUBROS: 'Rubros',
    LOCALIDADES:'Localidades',
    CONFIGURACIONES:'Configuraciones'
  },
};

export const MENSAJES_COMPONENTE_NOTIFICACIONES = {
  TITULO_MENSAJE_CONFIRMACION: 'Confirmación',
  TITULO_MENSAJE_ERROR: 'Han ocurrido errores que impiden continuar'
}

export const MENSAJES_DIALOG_CONFIRMACION = {
  ELIMINAR_ENTIDAD: {
      dialogType: 'WARNING',
      dialogMessage: 'Está seguro que desea eliminar la entidad',
      dialogTextConfirmar: 'Eliminar',
      dialogTextCancelar: 'Cancelar',
  } as ConfirmationDialogData,
}

export const MENSAJES_DIALOG_MOVEDSALDO = {
  MOVER_ENTIDADE: {
      dialogType: 'SUCCEED',
      dialogMessage: 'Movimiento de saldo asociado a Nido correctamente',
      dialogTextConfirmar: 'Aceptar'
  } as ConfirmationDialogData,
  MOVER_ENTIDADES: {
    dialogType: 'SUCCEED',
    dialogMessage: 'Movimientos de saldos asociados a Nido correctamente',
    dialogTextConfirmar: 'Aceptar'
} as ConfirmationDialogData,
}

export const FLAG_YES_NO = {
  SI: 'Si',
  NO: 'No'
}

export const FLAG_ESTADO = {
  ACTIVO: 'Activo',
  INACTIVO: 'Inactivo'
}

export const PIPE_FLAG_TRANSFORMACION = {
  activo_inactivo: 'activo/inactivo',
  yes_no: 'si/no',
  vitamina_mineral: 'vitamina/mineral',
  empresa_local: 'empresa/local'
}

export const FLAG_VITAMINA_MINERAL = {
  VITAMINA: 'Vitamina',
  MINERAL: 'Mineral'
}

export const FLAG_EMPRESA_LOCAL = {
  EMPRESA: 'Empresa',
  LOCAL: 'Local'
}
export const BREADCRUMB = {
MANTENIMIENTO_BASICO: {
  VALUES: {
    MOVIMIENTOS: 'Movimientos',
    NIDOS: 'Lista de Nidos',
    TIPOS_DE_DOCUMENTO: 'Lista de Tipos de Documentos',
    CATEGORIAS_EMPRESAS: 'Lista de Categorías',
    CLASES: 'Lista de Clases ',
    TIPOS_DE_VEHICULOS: 'Lista de Tipos de Vehículos',
    CATEGORIAS_DE_VEHICULOS: 'Lista de Categorías de Vehículos',
    UNIDADES_DE_PRODUCTOS: 'Lista de Unidades de Productos',
    PROCEDIMIENTOS_DE_CONSERVACION:'Lista de Procedimientos de Conservación',
    INGREDIENTES: 'Lista de Ingredientes',
    TIPOS_DE_CIERRE_DE_ENVASE:'Lista de Tipos de Cierres de Envase',
    COADYUVANTES: 'Lista de Coadyuvantes',
    MATERIALES:'Lista de Materiales',
    CONDICIONES_DE_ALMACEN: 'Lista de Condiciones de Almacén',
    ADITIVOS: 'Lista de Aditivos',
    FUNCIONES_ADITIVOS: 'Lista de Funciones de Aditivos',
    TIPOS_DE_ENVASES: 'Lista de Tipos de Envases',
    TIPOS_DE_EMPRESAS: 'Lista de Tipos de Empresas',
    GIROS_DE_VEHICULOS:'Lista de Giros de Vehículos',
    CAPITULOS_DE_PRODUCTO:'Lista de Capítulos de Producto',
    GIROS: 'Lista de Giros',
    SUBCLASES: 'Lista de SubClases ',
    LINEAS_DE_PRODUCCION: 'Lista de Líneas de Producción',
    LINEAS_DE_PRODUCCION_VEHICULO: 'Lista de Líneas de Producción de Vehículos',
    SECCIONES_DE_PRODUCTOS: 'Lista de Secciones de Productos',
    COMINACIONES_DE_ADITIVOS_Y_FUNIONES:'Lista de Combinaciones de Aditivos y sus Funciones',
    LIMITES_DE_ADITIVOS:'Lista de Límites de Aditivos',
    GIROS_DE_LOCALES:'Lista de Giros de Locales',
    SUBSECCIONES_DE_PRODUCTOS:'Lista de Sub Secciones de Productos',
    DENOMINACION_DE_VENTA_DE_PRODUCTO:'Lista de Denominaciones de Ventas de Productos',
    PRODUCTOS:'Lista de Productos',
    VITAMINA_Y_MINERALES:'Lista de Vitaminas y Minerales',
    MARCAS: 'Lista de Marcas',
    RUBROS: 'Lista de Rubros',
    LOCALIDADES:'Lista de Localidades',
    CONFIGURACIONES:'Lista de Configuraciones'
  },
  ALIAS: {
    MOVIMIENTOS: 'operacionCrudMantenimientoMovimiento',
    NIDOS: 'operacionCrudMantenimientoNidos',
    TIPOS_DE_DOCUMENTO: 'operacionCrudMantenimientoTiposDeDocumentos',
    CATEGORIAS_EMPRESAS: 'operacionCrudMantenimientoCategorias',
    CLASES: 'operacionCrudMantenimientoClasesDeLocales',
    TIPOS_DE_VEHICULOS: 'operacionCrudMantenimientoTiposDeVehiculo',
    CATEGORIAS_DE_VEHICULOS: 'operacionCrudMantenimientoCategoriasVehiculos',
    UNIDADES_DE_PRODUCTOS: 'operacionCrudMantenimientoUnidadesProductos',
    PROCEDIMIENTOS_DE_CONSERVACION:'operacionCrudMantenimientoProcedimientosConservacion',
    INGREDIENTES: 'operacionCrudMantenimientoIngredientes',
    TIPOS_DE_CIERRE_DE_ENVASE:'operacionCrudMantenimientoTiposCierresEnvase',
    COADYUVANTES: 'operacionCrudMantenimientoCoadyuvantes',
    MATERIALES:'operacionCrudMantenimientoMateriales',
    CONDICIONES_DE_ALMACEN: 'operacionCrudMantenimientoCondicionesDeAlmacen',
    ADITIVOS: 'operacionCrudMantenimientoAditivos',
    FUNCIONES_ADITIVOS: 'operacionCrudMantenimientoFuncionesDeAditivos',
    TIPOS_DE_ENVASES: 'operacionCrudMantenimientoTiposDeEnvases',
    TIPOS_DE_EMPRESAS: 'operacionCrudMantenimientoTiposEmpresas',
    GIROS_DE_VEHICULOS: 'operacionCrudMantenimientoGrirosDeVehiculos',
    CAPITULOS_DE_PRODUCTO: 'operacionCrudMantenimientoCapitulosDeProducto',
    GIROS:'operacionCrudMantenimientoGirosDeEmpresas',
    SUBCLASES: 'operacionCrudMantenimientoSubclasesDeLocales',
    LINEAS_DE_PRODUCCION: 'operacionCrudMantenimientoLineasDeProduccion',
    LINEAS_DE_PRODUCCION_VEHICULO: 'operacionCrudMantenimientoLineasDeProduccionVehiculo',
    SECCIONES_DE_PRODUCTOS: 'operacionCrudMantenimientoSeccionesDeProductos',
    COMINACIONES_DE_ADITIVOS_Y_FUNIONES: 'operacionCrudMantenimientoCombinacionesAditivos',
    LIMITES_DE_ADITIVOS:'operacionCrudMantenimientoLimitesdeAditivos',
    GIROS_DE_LOCALES:'operacionCrudMantenimientoGirosDeLocales',
    SUBSECCIONES_DE_PRODUCTOS:'operacionCrudMantenimientoSubSeccionesProductos',
    DENOMINACION_DE_VENTA_DE_PRODUCTO:'operacionCrudMantenimientoDenominacionesVentasProductos',
    PRODUCTOS:'operacionCrudMantenimientoProductos',
    VITAMINA_Y_MINERALES:'operacionCrudMantenimientoVitaminasMinerales',
    MARCAS: 'operacionCrudMantenimientoMarcas',
    RUBROS: 'operacionCrudMantenimientoRubros',
    LOCALIDADES:'operacionCrudMantenimientoLocalidades',
    CONFIGURACIONES:'operacionCrudMantenimientoConsiguraciones'
  }
}
}

export const VALIDATORFIELD = {
  MAXLENGTH: {
    VALUES: {
      SMALL: 20,
      SHORT: 80,
      LARGE: 200,
      LETTER:1,
   },
   MESSAGES:{
      SHORT: 'Máximo 80 caracteres',
      LARGE: 'Máximo 200 caracteres',
      SMALL: 'Máximo 20 caracteres',
      LETTER:'Máximo 1 caracteres',
   }
  },
  PATTERN:{
    VALUES :{
      ALFANUMERIC_INTERMEDIUM_SPACES: '[a-zA-Z0-9À-ÿ]+([ ][a-zA-Z0-9À-ÿ]+)*?',
      ALFANUMERIC_wHITOUT_SPACES: '[a-zA-Z0-9À-ÿ]+',
      NUMBERDECIMAL: '[0-9.]+',
      NUMBERINTEGER: '^[0-9]+$',
      LETTER: '[A-S]'
    },
    MESSAGES:{
      GENERIC:'Valor incorrecto'
   }
  }


}
