import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BooleanToStringPipe } from 'src/app/pipes/boolean-to-string.pipe';
import { DialogMoveNidoComponent } from './dialog-movenido/dialog-movenido.component';
import { DialogMovimientosComponent } from './dialog-movimientos/dialog-movimientos.component';
import { DialogMovimientosMultiplesComponent } from './dialog-movimientosmultiples/dialog-movimientosmultiples.component';
import { DialogNidoComponent } from './dialog-nido/dialog-nido.component';
import { GlobalSpinnerComponent } from './global-spinner/global-spinner.component';
import { GridFileDownloadComponent } from './grid-file-download/grid-file-download.component';
import { MaterialModule } from './material.module';
import { NotificationDialogComponent } from './notification-dialog/notification-dialog.component';
import { OptionsScrollDirective } from './select-lazy-autocomplete/options-scroll.directive';
import { SelectLazyAutocompleteComponent } from './select-lazy-autocomplete/select-lazy-autocomplete.component';

@NgModule({
  declarations: [
    GridFileDownloadComponent,
    BooleanToStringPipe,
    OptionsScrollDirective,
    SelectLazyAutocompleteComponent,
    NotificationDialogComponent,
    GlobalSpinnerComponent,
    DialogNidoComponent,
    DialogMovimientosComponent,
    DialogMovimientosMultiplesComponent,
    DialogMoveNidoComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    ReactiveFormsModule
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    BooleanToStringPipe,
    NotificationDialogComponent,
    GridFileDownloadComponent,
    OptionsScrollDirective,
    SelectLazyAutocompleteComponent,
    GlobalSpinnerComponent,
    DialogNidoComponent,
    DialogMovimientosComponent,
    DialogMovimientosMultiplesComponent,
    DialogMoveNidoComponent
  ],
})
export class SharedModule {}
