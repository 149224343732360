import { FormControl, FormGroup } from '@angular/forms';
import { format, isDate, parseISO } from 'date-fns';
import { Filtro } from '../models/filtros/filtro.model';
import { FiltroOrdenAtributo } from '../models/filtros/filtro-orden-atributo.model';
declare type SelectAutocompleteEntity = {
  id: number;
  codigo: string;
  nombre: string;
}
export class FilterUtils {
  static obtenerFiltros(
    filterForm: FormGroup
  ): Record<string, number | string | Date> {
    const filterData: Record<string, number | string | Date> = {};
    let value: string = '';
    if (filterForm.valid) {
      Object.keys(filterForm.controls).forEach((key) => {
        if (
          filterForm.get(key) instanceof FormControl &&
          filterForm.get(key)?.value !== null &&
          filterForm.get(key)?.value !== ''&& filterForm.get(key)?.value !== 'todos'
        ) {
          if (isDate(filterForm.get(key)?.value)) {
            value = format(parseISO(filterForm.get(key)?.value), 'DD-MM-YYYY');
            filterData[key] = value;
          } else {
            filterData[key] = filterForm.get(key)?.value;
          }
        }
      });
    }
    return filterData;
  }

  static crearFiltro(
    value?: Record<string, number | string | Date | boolean>,
    page?: number,
    pageSize?: number,
    orden?: FiltroOrdenAtributo | null
  ): Filtro {
    const filtro = new Filtro();
    if (page === null || pageSize === null) {
      filtro.omitirPaginado();
    } else {
      filtro.agregarPaginado(page, pageSize);
    }
    filtro.agregarFiltro(value);
    if (orden === null) {
      filtro.agregarOrden(new FiltroOrdenAtributo());
    } else {
      filtro.agregarOrden(orden);
    }
    return filtro;
  }

  static removeEmptyPropertiesOfObjects(
    object: Record<string, number | string | Date>
  ): void {
    Object.keys(object).forEach(
      (k) => (object[k] === '' || object[k] === null) && delete object[k]
    );
  }
  
  static isEmpty(filter: Record<string, number | string | Date>): boolean {
    return Object.keys(filter).length === 0;
  }
}
