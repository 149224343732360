import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { MantenimientosBasicosFiltroStateRepository } from 'src/app/state/mantenimientos-basicos/mantenimientos-basicos-filtros.repository.state.service';
import { SidenavService } from './../navigation/sidenav.service';

interface movimientosData {
  id: string;
  estado: boolean;
  fecha: string;
  descripcion: string;
  importe: string;
  operation: string;
  ennido:boolean;
  nidoid: string;
  cuenta: string;
  nidocolor:string;
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements AfterViewInit {
  @ViewChild('sidenav') materialSidenav: MatSidenav | undefined;
  movimientos:movimientosData[]=[];
  public onSideNavChange: boolean | undefined;

  constructor(private materialSideNav: SidenavService,
    private mantenimientosBasicosFiltroStateRepository: MantenimientosBasicosFiltroStateRepository<any>,
    private route: Router
    ) {}

  ngAfterViewInit(): void {
    if (this.materialSidenav) {
      this.materialSideNav.setSidenav(this.materialSidenav);
    }
    this.movimientos=[
      {
        id:'1',
        estado: true,
        fecha: '21/01/2023',
        descripcion: 'Retirada de efectivo',
        importe: '160',
        operation: 'resta',
        ennido: false,
        nidoid: '',
        nidocolor:'',
        cuenta: '124xxxxxxxx46753',
      },
      {
        id:'2',
        estado: true,
        fecha: '19/01/2023',
        descripcion: 'Pago alquiler',
        importe: '850',
        operation: 'resta',
        ennido: false,
        nidoid: '',
        cuenta: '124xxxxxxxx44123',
        nidocolor:'',
      },
      {
        id:'3',
        estado: true,
        fecha: '18/12/2022',
        descripcion: 'Pago EL FOGÓN VERDE',
        importe: '48,5',
        operation: 'resta',
        ennido: false,
        nidoid: '',
        cuenta: '124xxxxxxxx86542',
        nidocolor:'',
      },
      {
        id:'4',
        estado: true,
        fecha: '17/12/2022',
        descripcion: 'Transferencia emitida a QUEMUEL PANIAGUA PSICÓLOGO',
        importe: '60.00',
        operation: 'resta',
        ennido: false,
        nidoid: '',
        cuenta: '124xxxxxxxx48882',
        nidocolor:'',
      },
      {
        id:'5',
        estado: true,
        fecha: '13/12/2022',
        descripcion: 'Transferencia Bizum recibida',
        importe: '40.00',
        operation: 'suma',
        ennido: false,
        nidoid: '',
        cuenta: '124xxxxxxxx44765',
        nidocolor:'',
      },
      {
        id:'6',
        estado: true,
        fecha: '13/12/2022',
        descripcion: '‌Recibo ASOCIACIÓN BATIYE: (concepto: Cuota socios febrero)',
        importe: '10.00',
        operation: 'resta',
        ennido: false,
        nidoid: '',
        cuenta: '124xxxxxxxx22112',
        nidocolor:'',
      },
      {
        id:'7',
        estado: true,
        fecha: '07/12/2022',
        descripcion: '‌Recibo MAPFRE',
        importe: '70,21',
        operation: 'resta',
        ennido: false,
        nidoid: '',
        cuenta: '124xxxxxxxx53422',
        nidocolor:'',
      },
      {
        id:'8',
        estado: true,
        fecha: '07/12/2022',
        descripcion: '‌Transferencia recibida de A.E.A.T. DEVOLUCIONES TRIBUTARIAS AEAT APL:DECLARACION I.R.P.F.',
        importe: '309,66',
        operation: 'suma',
        ennido: false,
        nidoid: '',
        cuenta: '124xxxxxxxx44111',
        nidocolor:'',
      },
      {
        id:'9',
        estado: true,
        fecha: '05/12/2022',
        descripcion: '‌‌Comisión de mantenimiento de cuenta',
        importe: '3.00',
        operation: 'resta',
        ennido: false,
        nidoid: '',
        cuenta: '124xxxxxxxx66632',
        nidocolor:'',
      },
      {
        id:'10',
        estado: true,
        fecha: '05/12/2022',
        descripcion: '‌‌Recibo TELEFONÍA',
        importe: '34,18',
        operation: 'resta',
        ennido: false,
        nidoid: '',
        cuenta: '124xxxxxxxx9903',
        nidocolor:'',
      },
      {
        id:'11',
        estado: true,
        fecha: '04/12/2022',
        descripcion: '‌‌Pago en LA FISNA',
        importe: '50,80',
        operation: 'resta',
        ennido: false,
        nidoid: '',
        cuenta: '124xxxxxxxx41234',
        nidocolor:'',
      },
      {
        id:'12',
        estado: true,
        fecha: '03/12/2022',
        descripcion: '‌‌Transferencia recibida JULIA GARCÍA: (concepto: Gastos febrero) ',
        importe: '250',
        operation: 'suma',
        ennido: false,
        nidoid: '',
        cuenta: '124xxxxxxxx55632',
        nidocolor:'',
      },
      {
        id:'13',
        estado: true,
        fecha: '02/12/2022',
        descripcion: '‌Recibo ELECTRICIDAD',
        importe: '68,12',
        operation: 'resta',
        ennido: false,
        nidoid: '',
        cuenta: '124xxxxxxxx42122',
        nidocolor:'',
      }
      ,
      {
        id:'14',
        estado: true,
        fecha: '01/12/2022',
        descripcion: '‌‌Compra ALDI',
        importe: '77,85',
        operation: 'resta',
        ennido: false,
        nidoid: '',
        cuenta: '124xxxxxxxx44532',
        nidocolor:'',
      },
      {
        id:'15',
        estado: true,
        fecha: '27/11/2022',
        descripcion: '‌‌Ingreso nómina',
        importe: '1709,54',
        operation: 'suma',
        ennido: false,
        nidoid: '',
        cuenta: '124xxxxxxxx41132',
        nidocolor:'',
      }
    ]
    this.mantenimientosBasicosFiltroStateRepository.saveToSessionStorage(
      'movimientos-principal',
       this.movimientos
       
    );
    this.route.navigate(['consolidate/global']);
  }
}
