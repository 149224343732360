<div class="flex gap-4">
  <div class="flex items-center gap-2" *ngIf="loading$ | async">
    <mat-spinner
      aria-label="download-pdf-loading-spinner"
      color="primary"
      diameter="25"
      strokeWidth="2"
    >
    </mat-spinner>
    <span>Descargando</span>
  </div>
  <button
    type="button"
    class="flex items-center text-gray-700 disabled:bg-slate-400 focus:ring-4 border-[1px] border-blue-900 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-1.5 focus:outline-none"
    (click)="descargarCSV()"
    [disabled]="loading$ | async"
  >
    <mat-icon class="text-blue-700 cursor-pointer mr-1">file_download</mat-icon>
    <span>Descargar CSV</span>
  </button>
</div>
