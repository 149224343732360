<div class="flex items-center justify-center w-screen h-screen bg-blue-900">
  <div class="px-40 py-20 bg-white rounded-md shadow-xl">
    <div class="flex flex-col items-center gap-2">
      <p class="text-red-600 text-[40px]">ERROR</p>

      <h2 class="text-2xl font-bold text-center text-gray-800 md:text-3xl mt-1">
        <span>Descripción: {{ error }} </span>
      </h2>

      <p class="text-center text-gray-500 md:text-lg">
        La acción efectuada ha generado un error.
      </p>

      <a
        href=""
        class="px-6 py-2 text-sm font-semibold text-blue-800 bg-blue-100"
        >Ir al inicio</a
      >
    </div>
  </div>
</div>
