import { SidenavService } from './../sidenav.service';
import { TOP_NAVIGATION_BAR_ITEMS } from './top-navigation-bar-links';
import { Component } from '@angular/core';

@Component({
  selector: 'app-top-navigation-bar',
  templateUrl: './top-navigation-bar.component.html',
  styleUrls: ['./top-navigation-bar.component.scss'],
})
export class TopNavigationBarComponent {
  topNavigationBarItems = TOP_NAVIGATION_BAR_ITEMS;
  protected sideNavState: boolean = false;

  constructor(private materialSideNav: SidenavService) {}

  toggleSideNav(): void {
    this.materialSideNav.toggle();
    this.sideNavState = !this.sideNavState;
  }
}
