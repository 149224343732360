import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmationDialogData } from 'src/app/constants/user-interface/user-interface-messages.types';
import { NotificationDialogComponent } from './notification-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class NotificationDialogService {
  private matDialogConfig = { disableClose: true, autoFocus: false };

  constructor(public dialog: MatDialog) {}

  abrir(
    data: ConfirmationDialogData,
    options: MatDialogConfig = this.matDialogConfig
  ): Observable<any> {
    return this.dialog
      .open(NotificationDialogComponent, {
        width: options.width,
        disableClose: options.disableClose,
        autoFocus: options.autoFocus,
        data: {
          dialogType: data.dialogType,
          dialogMessage: data.dialogMessage,
          dialogTextConfirmar: data.dialogTextConfirmar,
          dialogTextCancelar: data.dialogTextCancelar
        },
      })
      .afterClosed();
  }
}
