import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ResponseBackend } from 'src/app/models/http-client/backend-response.model';

@Injectable({
  providedIn: 'root',
})
export class FacadeCustomHttpClientService {
  public backendURL = environment.URL_BACKEND;
  protected headers = new HttpHeaders({
    'Content-Type': 'application/json;charset=UTF-8',
  });

  private httpClient = inject(HttpClient);

  constructor() {}

  httpClientGet(
    endpoint: string,
    params?: any
  ): Observable<ResponseBackend<any>> {
    return this.httpClient.get<ResponseBackend<any>>(
      `${this.backendURL}${endpoint}`,
      {
        headers: this.headers,
        params: params,
      }
    );
  }

  httpClientPost(
    endpoint: string,
    params: any
  ): Observable<ResponseBackend<any>> {
    return this.httpClient.post<ResponseBackend<any>>(
      `${this.backendURL}${endpoint}`,
      JSON.stringify(params),
      { headers: this.headers }
    );
  }

  httpClientPut(
    endpoint: string,
    params: any
  ): Observable<ResponseBackend<any>> {
    return this.httpClient.put<ResponseBackend<any>>(
      `${this.backendURL}${endpoint}`,
      JSON.stringify(params),
      {
        headers: this.headers,
      }
    );
  }

  httpClientDelete(endpoint: string): Observable<ResponseBackend<any>> {
    return this.httpClient.delete<ResponseBackend<any>>(
      `${this.backendURL}${endpoint}`,
      {
        headers: this.headers,
      }
    );
  }

  downloadFileAsText(
    endpoint: string,
    params: any = null,
  ): Observable<HttpResponse<string>> {
    return this.httpClient.post(
      `${this.backendURL}${endpoint}`,
      JSON.stringify(params),
      { headers: this.headers, responseType: 'text', observe: 'response' }
    );
  }
}
