import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { DashboardComponent } from './app-layout/dashboard/dashboard.component';
import { HomeComponent } from './app-layout/home/home.component';
import { ErrorPageComponent } from './app-layout/error-page/error-page.component';
import { NotFoundPageComponent } from './app-layout/not-found-page/not-found-page.component';
import { LoginComponent } from './app-layout/login/login.component';
import { VerifyAccesComponent } from './app-layout/verify-acces/verify-acces.component';
import { AuthenticationGuard } from 'src/app/guards/auth/authentication.guard';

const routes: Routes = [
  // {
  //   path: '',
  //   pathMatch: 'full',
  //   canActivate: [AuthenticationGuard],
  //   component: DashboardComponent,
  // },
  // {
  //   path: 'verifiy-access',
  //   title: 'NEST - Verificando Acceso',
  //   component: VerifyAccesComponent,
  // },
  {
    path: '',
    title: 'NEST - Dashboard',
    data: { breadcrumb: 'Inicio' },
    component: DashboardComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
        children: [
          {
            path: 'consolidate',
            data: {
              breadcrumb: { label: 'Posición consolidada', disable: true },
            },
            loadChildren: () =>
              import(
                './business-runaev/mantenimientos-basicos/mantenimientos-basicos.module'
              ).then((m) => m.MantenimientosBasicosModule),
          },
        ],
      },
    ],
  },
  {
    path: 'error',
    component: ErrorPageComponent,
  },
  {
    path: '404',
    component: NotFoundPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
