<form [formGroup]="formParent">
  <div class="flex gap-4 items-baseline">
    <mat-label class="text-sm font-medium text-gray-900"
      >{{ params?.titulo }}:</mat-label
    >
    <div class="ml-auto">
      <mat-form-field appearance="outline">
        <input
          type="text"
          placeholder="Buscar"
          matInput
          [formControlName]="parentFormContorlName"
          [matAutocomplete]="auto"
          (keypress)="emiteKeyPressEvent()"
          (keydown)="emiteKeyPressEvent()"
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          [displayWith]="displayWith"
          showPanel="true"
          (optionsScroll)="onScroll()"
          (optionSelected)='selectOption($event.option.value)'
        >
          <mat-option
            *ngFor="let option of filteredEntity$ | async"
            [value]="option"
          >
            {{ option.nombre }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="isRequiredValidator"
          >{{ constants.CAMPO_REQUERIDO }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</form>
