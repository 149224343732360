import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { NavigationExtras, Router } from '@angular/router';
import { BehaviorSubject, finalize, Observable, takeUntil } from 'rxjs';
import { APP_ROUTES } from 'src/app/constants/routes/mantenimientos-basicos/routes-mantenimientos-basicos.constants';
import { CustomTableDataSource } from 'src/app/datasources/custom-table.datasource';
import { EntidadBase } from 'src/app/models/entidades-negocio/entidad-base.model';
import { FiltroOrdenAtributo } from 'src/app/models/filtros/filtro-orden-atributo.model';
import { Filtro } from 'src/app/models/filtros/filtro.model';
import { ResponseBackend } from 'src/app/models/http-client/backend-response.model';
import { CustomHttpClientService } from 'src/app/services/http-web-client/custom-http-client.service';
import { MantenimientosBasicosFiltroStateRepository } from 'src/app/state/mantenimientos-basicos/mantenimientos-basicos-filtros.repository.state.service';
import { FilterUtils } from 'src/app/utils/filter-utils';
import { MATERIAL_TABLE_TRADUCCCION_PAGINADOR } from './../../../../constants/user-interface/material-table-paginator-translation.constants';
interface movimientosData {
  id: string;
  estado: boolean;
  fecha: string;
  descripcion: string;
  importe: string;
  operation: string;
  ennido:boolean;
  nidoid: string;
}
@Injectable({
  providedIn: 'root',
})
export class GridMantenimientosBasicosService<T extends EntidadBase> {
  pageIndex: number = -1;
  pageSize: number = -1;
  dataSource!: CustomTableDataSource<T>;
  paginator!: MatPaginator;
  sort!: MatSort;
  sortingStore = new BehaviorSubject({} as FiltroOrdenAtributo);
  apiUrl: string = '';
  selectObjectNidos!: any;
  private objectFilterNidos = new BehaviorSubject<any>(this.selectObjectNidos);
  constructor(
    private customHttpClientService: CustomHttpClientService<T>,
    private router: Router,
    private mantenimientosBasicosFiltroStateRepository: MantenimientosBasicosFiltroStateRepository<movimientosData>,
  
  ) {}

  cargarDatos(
    filtros?: Record<string, number | string | Date | boolean>
  ): void {
    this.filtrar(
      new Filtro(filtros).agregarPaginado(this.pageIndex, this.pageSize)
    );
    if (this.paginator !== undefined) {
      this.paginator.pageIndex = this.pageIndex;
      this.paginator.pageSize = this.pageSize;
    }
  }
  cargarMovimientos(){
    let movimientos =
    this.mantenimientosBasicosFiltroStateRepository.getFromSessionStorage(
      'movimientos-principal'
    );
    let valuesMovimientos: any[]=[];
    if(movimientos){
      valuesMovimientos= Object.values(movimientos);
      this.dataSource.total.next(valuesMovimientos.length);
        if (valuesMovimientos.length>0) {
          this.dataSource.tableDataStream.next(valuesMovimientos);
        } else {
          this.dataSource.tableDataStream.next([]);
        }
    }
  }

  filtrar(filtros: Filtro): void {
    this.agregarOrdenYPaginadoAlFiltro(filtros);
    this.customHttpClientService
      .filtrar(this.apiUrl, filtros)
      .pipe(
        takeUntil(this.dataSource.unsubscribe)
      )
      .subscribe((responseBackend: ResponseBackend<any>) => {
        this.dataSource.total.next(responseBackend.total);
        if (responseBackend.respuesta) {
          this.dataSource.tableDataStream.next(responseBackend.respuesta);
        } else {
          this.dataSource.tableDataStream.next([]);
        }
      });
  }

  descargarCSV(filtros: Filtro): Observable<HttpResponse<string>> {
    this.agregarOrdenYPaginadoAlFiltro(filtros);
    return this.customHttpClientService.downloadFileAsText(
      `${this.apiUrl}/export`,
      filtros
    );
  }

  listenTablePagination(formularioFiltros?: FormGroup): void {
    if (this.paginator !== undefined) {
      this.paginator.page
        .pipe(takeUntil(this.dataSource.unsubscribe))
        .subscribe((paginator: PageEvent) => {
          const sorting = this.sortingStore.getValue();
          if (formularioFiltros) {
            const filtros = FilterUtils.crearFiltro(
              FilterUtils.obtenerFiltros(formularioFiltros),
              paginator.pageIndex,
              paginator.pageSize,
              sorting
            );
            this.filtrar(filtros);
          } else {
            this.filtrar(new Filtro());
          }
        });
    }
  }

  listenTableSorting(): void {
    this.sort.sortChange
      .pipe(takeUntil(this.dataSource.unsubscribe))
      .subscribe((sortConfig: Sort) => {
        const sortingConfiguration: FiltroOrdenAtributo = {
          atributo: sortConfig.active,
          ordenAscendente: this.sort.direction === 'asc' ? true : false,
        };
        if (sortingConfiguration) {
          this.sortingStore.next(sortingConfiguration);
        }
        this.paginator.pageIndex = 0;
      });
    this.dataSource.sort = this.sort;
  }

  ordenar(formularioFiltros?: FormGroup): void {
    if (formularioFiltros) {
      const filtros = FilterUtils.crearFiltro(
        FilterUtils.obtenerFiltros(formularioFiltros),
        this.paginator.pageIndex,
        this.paginator.pageSize,
        new FiltroOrdenAtributo(
          this.sort.active,
          this.sort.direction === 'asc' ? true : false
        )
      );
      this.filtrar(filtros);
    } else {
      this.filtrar(new Filtro());
    }
  }

  traducirPaginador(): void {
    if (this.paginator !== undefined && this.paginator !== null) {
      const paginatorIntl = this.paginator._intl;
      paginatorIntl.itemsPerPageLabel =
        MATERIAL_TABLE_TRADUCCCION_PAGINADOR.itemsPerPageLabel;
      paginatorIntl.nextPageLabel =
        MATERIAL_TABLE_TRADUCCCION_PAGINADOR.paginaSiguiente;
      paginatorIntl.previousPageLabel =
        MATERIAL_TABLE_TRADUCCCION_PAGINADOR.paginaAnterior;
      paginatorIntl.firstPageLabel =
        MATERIAL_TABLE_TRADUCCCION_PAGINADOR.primeraPagina;
      paginatorIntl.lastPageLabel =
        MATERIAL_TABLE_TRADUCCCION_PAGINADOR.ultimaPagina;
      paginatorIntl.getRangeLabel =
        GridMantenimientosBasicosService.getRangeLabel.bind(this);
    }
  }

  static getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
      return `Página 0 de ${length} `;
    }
    length = Math.max(length, 0);
    const cantidadTotalPaginas = Math.ceil(length / pageSize);
    return `Página ${page + 1} de ${cantidadTotalPaginas}`;
  }

  get gridIsEmpty(): boolean {
    return this.dataSource.total.getValue() > 0;
  }

  navigateToFormularioCrear(): void {
    this.router.navigate([
      `${this.router.url}/${APP_ROUTES.MANTENIMIENTOS_BASICOS.OPERACION_CREAR}`,
    ]);
  }

  navigateToFormularioModificar(rowData: T): void {
    this.router.navigate(
      [
        `${this.router.url}/${APP_ROUTES.MANTENIMIENTOS_BASICOS.OPERACION_MODIFICAR}`,
      ],
      this.generateQueryParams(rowData)
    );
  }

  navigateToFormularioVisualizar(rowData: T): void {
    this.router.navigate(
      [
        `${this.router.url}/${APP_ROUTES.MANTENIMIENTOS_BASICOS.OPERACION_VISUALIZAR}`,
      ],
      this.generateQueryParams(rowData)
    );
  }

  eliminarEntidad(idEntidad: number,filtros?: Record<string, number | string | Date | boolean>): void {
    this.customHttpClientService
      .delete(this.apiUrl, idEntidad)
      .pipe(
        takeUntil(this.dataSource.unsubscribe),
        finalize(() => {
          this.cargarDatos(filtros);
        })
      )
      .subscribe();
  }

  protected generateQueryParams(rowData: T): NavigationExtras {
    return {
      queryParams: {
        id: rowData.id,
      },
    };
  }

  protected agregarOrdenYPaginadoAlFiltro(filtros: Filtro): void {
    if (this.paginator !== undefined) {
      filtros
        .agregarPaginado(this.paginator.pageIndex, this.paginator.pageSize)
        .agregarOrden(
          new FiltroOrdenAtributo(
            this.sort.active,
            this.sort.direction === 'asc' ? true : false
          )
        );
    }
  }

  defineChildtoFilter(Nidosvalue: any): void {
    this.objectFilterNidos.next(Nidosvalue);
  }
  getChildtoFilterNidos(): BehaviorSubject<any> {
    return this.objectFilterNidos;
  }

  
}
