import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MENSAJES_VALIDACION_CAMPOS } from 'src/app/constants/forms/mensajes-validacion-campos';
import { VALIDATORFIELD } from 'src/app/constants/user-interface/user-interface-messages.constants';
import { ConfirmationDialogData, DialogNidoData } from 'src/app/constants/user-interface/user-interface-messages.types';
import { NomencladorBase } from 'src/app/models/entidades-negocio/nomenclador-base.model';
import * as chroma from 'chroma-js';

@Component({
  selector: 'app-dialog-nido',
  templateUrl: './dialog-nido.component.html',
  styleUrls: ['./dialog-nido.component.scss'],
})
export class DialogNidoComponent {
  dataForm: FormGroup;
  constants = MENSAJES_VALIDACION_CAMPOS;
  constantesValidations= VALIDATORFIELD;
  showForm=false;
  estados: NomencladorBase[] = [
    { codigo: true, nombre: 'Activo' },
    { codigo: false, nombre: 'Inactivo' },
  ];
  colors:any=['#000000','#FFC300 ','#FFC305','#2874A6','#D35400','#5D6D7E','#8E44AD','#F1C40F','#229954','#641E16','#333FFF','#FF3342'];
  selectedColor: any;
  constructor(
    public dialogRef: MatDialogRef<DialogNidoComponent>,
    @Inject(MAT_DIALOG_DATA)
    public confirmationDialogData: DialogNidoData,
    private formBuilder: FormBuilder,
  ) {
    this.dataForm = this.formBuilder.group({
      nombre: new FormControl<string | null>(null, [Validators.required]),
      estado: new FormControl<boolean>(true),
      color:new FormControl<string | null>(null, [Validators.required]),
    
    });
  }

  selectColor(color: string) {
    this.selectedColor = color;
  }
  ngAfterViewInit(){
    if(this.confirmationDialogDataData&&this.confirmationDialogDataData.nombre){
      this.dataForm.controls['nombre'].setValue(this.confirmationDialogDataData.nombre);
      this.dataForm.controls['estado'].setValue(this.confirmationDialogDataData.estado);
      this.dataForm.controls['color'].setValue(this.confirmationDialogDataData.color);

    }
    this.showForm=true
  }
  cerrarDialog(): void {
    this.dialogRef.close(false);
  }

  confirmarAccionDialog(): void {
    let result: any;
      result={
        nombre:this.dataForm.controls['nombre'].value,
        estado: this.dataForm.controls['estado'].value,
        color: this.dataForm.controls['color'].value
      }
    this.dialogRef.close(result);
  }

  get confirmationDialogDataTitulo(): string {
    return this.confirmationDialogData.dialogTitulo;
  }

  get confirmationDialogDataData(): any {
    return this.confirmationDialogData.data;
  }
}
