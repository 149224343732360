import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MENSAJES_VALIDACION_CAMPOS } from 'src/app/constants/forms/mensajes-validacion-campos';
import { VALIDATORFIELD } from 'src/app/constants/user-interface/user-interface-messages.constants';
import { ConfirmationDialogData, DialogNidoData } from 'src/app/constants/user-interface/user-interface-messages.types';
import { NomencladorBase } from 'src/app/models/entidades-negocio/nomenclador-base.model';

@Component({
  selector: 'app-dialog-movimientosmultiples',
  templateUrl: './dialog-movimientosmultiples.component.html',
  styleUrls: ['./dialog-movimientosmultiples.component.scss'],
})
export class DialogMovimientosMultiplesComponent {
  dataForm: FormGroup;
  constants = MENSAJES_VALIDACION_CAMPOS;
  constantesValidations= VALIDATORFIELD;
  showForm=false;
  Nidos:any=[];
  pasos: NomencladorBase[] = [
    { codigo: true, nombre: 'Si' },
    { codigo: false, nombre: 'No' },
  ];
  constructor(
    public dialogRef: MatDialogRef<DialogMovimientosMultiplesComponent>,
    @Inject(MAT_DIALOG_DATA)
    public confirmationDialogData: DialogNidoData,
    private formBuilder: FormBuilder,
  ) {
    this.dataForm = this.formBuilder.group({
      nido: new FormControl<string | null>(null, [Validators.required])
    });
  }


  ngAfterViewInit(){
    let nidosCharge= sessionStorage.getItem('Nidos');
    if(nidosCharge){

      const nidosJSONParse= JSON.parse(nidosCharge);
      if(nidosJSONParse){
  
          // Recorrer el arreglo utilizando un bucle for...of
          for (let nido of nidosJSONParse) {
           this.Nidos.push(nido)
          }
          this.Nidos.forEach((element:any)=> {
            element.saldo
          });
      
      }
    
    }
    this.showForm=true
  }
  cerrarDialog(): void {
    this.dialogRef.close(false);
  }

  confirmarAccionDialog(): void {
    let result: any;
      result={
        nido:this.dataForm.controls['nido'].value.id,
        color:this.dataForm.controls['nido'].value.color
      }
    this.dialogRef.close(result);
  }

  get confirmationDialogDataTitulo(): string {
    return this.confirmationDialogData.dialogTitulo;
  }

  get confirmationDialogDataData(): any {
    return this.confirmationDialogData.data;
  }
}
