import { HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Filtro } from 'src/app/models/filtros/filtro.model';
import { ResponseBackend } from 'src/app/models/http-client/backend-response.model';
import { FacadeCustomHttpClientService } from './facade-custom-http-client.service';

@Injectable({
  providedIn: 'root',
})
export class CustomHttpClientService<T> {
  private facadeCustomHttpClientService = inject(FacadeCustomHttpClientService);

  constructor() {}

  get(endpoint: string, params: any): Observable<ResponseBackend<T[]>> {
    return this.facadeCustomHttpClientService.httpClientGet(endpoint, params);
  }

  post(endpoint: string, params: any): Observable<ResponseBackend<T[]>> {
    return this.facadeCustomHttpClientService.httpClientPost(endpoint, params);
  }

  put(endpoint: string, params: any): Observable<ResponseBackend<T[]>> {
    return this.facadeCustomHttpClientService.httpClientPut(endpoint, params);
  }

  delete(endpoint: string, idEntidad: number): Observable<ResponseBackend<T>> {
    return this.facadeCustomHttpClientService.httpClientDelete(
      `${endpoint}/${idEntidad}`
    );
  }

  getById(
    endpoint: string,
    idEntidad: number
  ): Observable<ResponseBackend<T>> {
    return this.facadeCustomHttpClientService.httpClientGet(
      `${endpoint}/${idEntidad}`
    );
  }

  filtrar(endpoint: string, filtros: Filtro): Observable<ResponseBackend<T[]>> {
    return this.post(`${endpoint}/filtrar`, filtros);
  }

  downloadFileAsText(endpoint: string, params: any): Observable<HttpResponse<string>> {
    return this.facadeCustomHttpClientService.downloadFileAsText(
      endpoint,
      params
    );
  }

  getdataServ(endpoint: string, params?: any): Observable<ResponseBackend<T[]>> {
    return this.facadeCustomHttpClientService.httpClientGet(endpoint, params);
  }
}
