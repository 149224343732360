<mat-tree
  [dataSource]="dataSource"
  [treeControl]="treeControl"
  class="block"
 *ngIf="showmenu">
  <!-- This is the tree node template for leaf nodes -->
  <!-- There is inline padding applied to this node using styles.
      This padding value depends on the mat-icon-button width. -->
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle class="topnavigate">
    <li class="flex items-center flex-1 break-words mx-2 hover:bg-slate-300 hover:rounded-xl cursor-pointer">
      <!-- use a disabled button to provide padding for tree leaf -->
      <button mat-icon-button disabled *ngIf="node.icon">
        <img alt="logo" src={{node.icon}} style="margin-left: 20px;
        width: 22px;
        padding-right: 10%;" >
      </button>
      <a routerLink="{{ node.route }}" class="subleveltextmenu">{{ node.name }}</a>
    </li>
  </mat-tree-node>
  <!-- This is the tree node template for expandable nodes -->
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" >
    <li class="mt-0 mb-0 list-none">
      <div class="flex items-center flex-1 break-words ml-2 textstyleopen">
        <button
          mat-icon-button
          matTreeNodeToggle
          [attr.aria-label]="'Toggle ' + node.name"
        >
          <mat-icon class="mat-icon-rtl-mirror">
            {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
          </mat-icon>
        </button>
        {{ node.name }}
      </div>
      <!-- There is inline padding applied to this ul using styles.
            This padding value depends on the mat-icon-button width.  -->
      <ul [class.hidden]="!treeControl.isExpanded(node)" role="group" class="mt-0 mb-0 list-none pl-10">
        <ng-container matTreeNodeOutlet ></ng-container>
      </ul>
    </li>
  </mat-nested-tree-node>
</mat-tree>
