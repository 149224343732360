import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  debounceTime,
  filter,
  Observable,
  scan,
  startWith,
  Subject,
  switchMap,
  takeWhile,
  tap,
} from 'rxjs';
import { MENSAJES_VALIDACION_CAMPOS } from 'src/app/constants/forms/mensajes-validacion-campos';
import { VALIDATORFIELD } from 'src/app/constants/user-interface/user-interface-messages.constants';
import { Filtro } from 'src/app/models/filtros/filtro.model';
import { GridMantenimientoMovimientosData } from '../../pages/business-runaev/mantenimientos-basicos/mantenimiento-movimientos/grid-mantenimiento-movimientos/grid-mantenimiento-movimientos-data.model';
import { GridMantenimientoMovimientosComponent } from '../../pages/business-runaev/mantenimientos-basicos/mantenimiento-movimientos/grid-mantenimiento-movimientos/grid-mantenimiento-movimientos.component';

import { SelectLazyAutocompleteParamsData } from './select-lazy-autocomplete-params.model';
import { SelectLazyAutocompleteService } from './select-lazy-autocomplete.service';
import { SelectAutocompleteEntity } from './select-lazy-autocomplete.service';



@Component({
  selector: 'app-select-lazy-autocomplete',
  templateUrl: './select-lazy-autocomplete.component.html',
  styleUrls: ['./select-lazy-autocomplete.component.scss'],
})
export class SelectLazyAutocompleteComponent
  implements AfterViewInit, OnDestroy
{
  filteredEntity$: Observable<SelectAutocompleteEntity[]> | undefined;
  defultValueSelected: SelectAutocompleteEntity={
    searchField: 'nombre',
    id: 0,
    codigo: '',
    nombre: '',
  }
  @Input() params: SelectLazyAutocompleteParamsData | undefined = undefined;
  @Input() formParent: FormGroup;
  @Output() selectedValue = new EventEmitter<GridMantenimientoMovimientosData>();
  @Output() selectedValueFilter = new EventEmitter<SelectAutocompleteEntity>();
  @Output() typingKey = new EventEmitter<boolean>();
  
  usedSearchFather=false;
  private nextPage$ = new Subject();
  private responseBackendCollection: SelectAutocompleteEntity[] = [];
  unsubscribe = new Subject();
  constants = MENSAJES_VALIDACION_CAMPOS;
  constantesValidations= VALIDATORFIELD;
  constructor(
    // TODO tipar el signature del generic
    private formBuilder: FormBuilder,
    private selectLazyAutocompleteService: SelectLazyAutocompleteService<any>
  ) {
    this.formParent = this.formBuilder.group({});
    this.selectLazyAutocompleteService
      .getChildtoFilter()
      .subscribe(async (value) => {
        if(this.params){
          if(value.searchField===this.params.searchFieldFather){
                this.usedSearchFather=true;
                this.callFieldChange(value);
          }
        }
       
      });
  }
  ngAfterViewInit(): void {
    this.callFieldChange(this.defultValueSelected);
  }

  callFieldChange(searchField: SelectAutocompleteEntity){
    if (this.formParent && this.params?.parentFormControlName) {
      const filter$ = this.formParent.controls[
        this.params.parentFormControlName
      ].valueChanges.pipe(
        startWith(''),
        debounceTime(500),
        filter((filteredValue) => typeof filteredValue === 'string')
      );
      this.filteredEntity$ = filter$.pipe(
        switchMap((filter) => {
          let currentPage = 0;
          return this.nextPage$.pipe(
            startWith(currentPage),
            switchMap(
              async (_) => await this.getListEntity(filter, currentPage,searchField)
            ),
            tap(() => currentPage++),
            takeWhile((collection) => collection.length > 0 ),
            scan(
              (allProducts: any, newProducts: any) =>
                allProducts.concat(newProducts),
              []
            )
          );
        })
      );
    }
  }

  get isRequiredValidator(): boolean {
    if (this.params) {
      if (
        this.formParent
          ?.get(this.params.parentFormControlName)
          ?.hasError('required')
      ) {
        return true;
      }
    }
    return false;
  }

  get parentFormContorlName(): string {
    return this.params ? this.params.parentFormControlName : '';
  }

  ngOnDestroy(): void {
    this.unsubscribe.complete();
    this.unsubscribe.unsubscribe();
  }

  selectOption(option: any): void {
    this.selectedValue.emit(option);
    this.selectedValueFilter.emit(option);
  }

  // scrolling autocomplete
  displayWith(element: any) {
    return element ? element.nombre : null;
  }

  onScroll() {
    // This is called multiple times after the scroll has reached the 80% threshold position.
    this.nextPage$.next(true);
  }

  // getData from backend to filtered
  async getListEntity(
    startsWith: any,
    page: number,
    searchField: SelectAutocompleteEntity
  ): Promise<SelectAutocompleteEntity[]> {
    if (this.params) {
      let backendResponse: any;
        backendResponse= await this.getPromiseEntity(
         this.params.apiUrl,
         page,
         searchField
       );

      this.responseBackendCollection = backendResponse.respuesta;
      const filtered = this.responseBackendCollection.filter((option) =>
        option.nombre.toLowerCase().startsWith(startsWith.toLowerCase())
      );
      return filtered;
    }
    return [];
  }

  // call api with filters
  getPromiseEntity(url: string, page: number, fieldAutoComplete: SelectAutocompleteEntity) {
    return new Promise((resolver) => {
      this.selectLazyAutocompleteService.apiUrl = url;
      if (this.formParent && this.params) {
        let filter: Filtro;
          const searchCriteria =
          typeof this.formParent.controls[this.params.parentFormControlName]
            .value === 'object'
            ? ''
            : this.formParent.controls[this.params.parentFormControlName].value;
          filter = this.selectLazyAutocompleteService.crearFiltroEntidad({
          value:  {
            atributo: {
              [fieldAutoComplete.searchField=='nombre'?fieldAutoComplete.searchField:fieldAutoComplete.searchField+'Id']: fieldAutoComplete.searchField=='nombre'? searchCriteria: fieldAutoComplete.id,
              activo: true,
            }
          },
          page: page,
          length: 10,
        });
        if(this.usedSearchFather&&searchCriteria!=""){
          filter.filtros[this.defultValueSelected.searchField]=searchCriteria;
        }
        
        this.selectLazyAutocompleteService.filtrarEntidad(filter).subscribe({
          next: (response) => {
            this.responseBackendCollection = response.respuesta;
            return resolver(response);
          },
          error: (error: string) => {
            console.log(error);
          },
        });
      }
    });
  }

  emiteKeyPressEvent(){
    this.typingKey.emit(true);
  }
}
