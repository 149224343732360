import { Subject } from 'rxjs';
import { MatDrawerToggleResult, MatSidenav } from '@angular/material/sidenav';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'any',
})
export class SidenavService {
  private sidenav: MatSidenav | undefined;

  public sideNavState$: Subject<boolean> = new Subject();

  public setSidenav(sidenav: MatSidenav) {
    this.sidenav = sidenav;
  }

  public toggle(): Promise<MatDrawerToggleResult> | null {
    return this.sidenav ? this.sidenav.toggle() : null;
  }

  constructor() {}
}
