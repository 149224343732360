<section class="grid grid-rows-2 justify-items-center items-center">
  <header>
    <ng-container [ngSwitch]="confirmationDialogDataType">
      <ng-container *ngSwitchCase="'SUCCEED'">
        <img
          alt="confirm"
          src="assets\images\icons\notification-popup\confirm.svg"
          width="96px"
          height="96px"
        />
      </ng-container>
      <ng-container *ngSwitchCase="'WARNING'">
        <img
          alt="warning"
          src="assets\images\icons\notification-popup\warning.svg"
          width="96px"
          height="96px"
        />
      </ng-container>
      <ng-container *ngSwitchCase="'ERROR'">
        <img
          alt="error"
          src="assets\images\icons\notification-popup\error.svg"
          width="96px"
          height="96px"
        />
      </ng-container>
    </ng-container>
  </header>

  <article class="text-center mt-1">
    <h3 class="m-0">
      {{ confirmationDialogData.dialogMessage }}
    </h3>
  </article>

  <div class="flex justify-center gap-4">
    <div *ngIf="confirmationDialogData.dialogTextConfirmar">
      <button
        color="accent"
        mat-raised-button
        (click)="confirmarAccionDialog()"
      >
        <span class="text-white">
          {{ confirmationDialogData.dialogTextConfirmar }}
        </span>
      </button>
    </div>
    <div *ngIf="confirmationDialogData.dialogTextCancelar">
      <button
        class="ml-4"
        color="warn"
        mat-raised-button
        (click)="cerrarDialog()"
      >
        <span class="text-white">
          {{ confirmationDialogData.dialogTextCancelar }}
        </span>
      </button>
    </div>
  </div>
</section>
