import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './content/core/core.module';
import { PagesModule } from './content/pages/pages.module';
import { HttpHandlerInterceptor } from './interceptors/http-handler.interceptor';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, AppRoutingModule, CoreModule, PagesModule],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: HttpHandlerInterceptor, multi: true}
],
  bootstrap: [AppComponent],
})
export class AppModule {}
