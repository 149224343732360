export const STATUS_NOTIFICATION = {
  OK: 'OK',
  ERROR: 'ERROR',
  SUCCESS_NOTIFICATION: 'success-notification',
  ERROR_NOTIFICATION: 'error-notification',
  ERROR_DESCONOCIDO: 'Error desconocido. Contacte con su administrador',
};

export const TIEMPO_NOTIFICACIONES = {
  MILISEGUNDOS: 6000
}