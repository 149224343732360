import { FiltroOrdenAtributo } from './filtro-orden-atributo.model';
import { FiltroGenerico } from './filtro-generico.model';

export class Filtro {
  constructor(
    public filtros: any = new FiltroGenerico(),
    public orden: FiltroOrdenAtributo | undefined = new FiltroOrdenAtributo(),
    public registrosPorPaginas: number | undefined = -1,
    public pagina: number | undefined = -1
  ) {}

  agregarFiltro(filtro: Record<string, number | string | Date | boolean> | undefined): Filtro {
    this.filtros = filtro;
    return this;
  }

  agregarDatoBusquedaGenerica(datoBusquedaGenerica?: string): Filtro {
    this.filtros.dato = datoBusquedaGenerica;
    return this;
  }

  agregarOrden(filtroOrdenAtributo: FiltroOrdenAtributo | undefined): Filtro {
    this.orden = filtroOrdenAtributo;
    return this;
  }

  borrarFiltros(): Filtro {
    this.filtros = {};
    return this;
  }

  borrarOrden(): Filtro {
    this.orden = new FiltroOrdenAtributo();
    return this;
  }

  omitirPaginado(): Filtro {
    this.registrosPorPaginas = -1;
    this.pagina = -1;
    return this;
  }

  agregarPaginado(
    pagina: number | undefined,
    registrosPorPaginas: number | undefined
  ): Filtro {
    this.registrosPorPaginas = registrosPorPaginas;
    this.pagina = pagina;
    return this;
  }
}
