import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalSpinnerService } from './global-spinner.service';

@Component({
  selector: 'app-global-spinner',
  templateUrl: './global-spinner.component.html',
  styleUrls: ['./global-spinner.component.scss'],
})
export class GlobalSpinnerComponent {
  constructor(private globalSpinnerService: GlobalSpinnerService) {}

  get globalSpinnerStream$(): Observable<boolean> {
    return this.globalSpinnerService.isLoading$;
  }
}
