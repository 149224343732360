export interface ISideBarMenuNode {
  name: string;
  route?: string;
  icon?: string;
  children?: ISideBarMenuNode[];
}

export const SIDEBAR_MENU_LINKS: ISideBarMenuNode[] = [
  {
    name: 'Configuración',
    route: 'consolidate/configuraciones',
    icon: 'assets/images/icons/config.png',
  },
  {
    name: 'Nidos',
    route: 'map',
    icon: 'map',
    children: [
    ],
  },
];
