import {
  ISideBarMenuNode,
  SIDEBAR_MENU_LINKS,
} from './left-sidebar-navigation-tree-links';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { MantenimientosBasicosFiltroStateRepository } from 'src/app/state/mantenimientos-basicos/mantenimientos-basicos-filtros.repository.state.service';
import { GridMantenimientosBasicosService } from '../../../business-runaev/mantenimientos-basicos/grid-mantenimientos-basicos.service';
import { LayoutGridMantenimientosBasicosComponent } from '../../../business-runaev/mantenimientos-basicos/common-mantenimientos-basicos/layout-mantenimientos-basicos/layout-grid-mantenimientos-basicos/layout-grid-mantenimientos-basicos.component';
interface NidosData {
  id: string;
  nombre: string;
  saldo: number;
  estado: boolean;
  color: string
}
@Component({
  selector: 'app-left-sidebar-navigation-tree',
  templateUrl: './left-sidebar-navigation-tree.component.html',
  styleUrls: ['./left-sidebar-navigation-tree.component.scss'],
})
export class LeftSidebarNavigationTreeComponent implements OnInit {
  treeControl = new NestedTreeControl<ISideBarMenuNode>(
    (node) => node.children
  );
  dataSource = new MatTreeNestedDataSource<ISideBarMenuNode>();
  showmenu=false;
  Nidos:NidosData[]=[];
  constructor(private mantenimientosBasicosFiltroStateRepository: MantenimientosBasicosFiltroStateRepository<NidosData>,
    private gridMantenimientosBasicosService: GridMantenimientosBasicosService<any>) {
    this.gridMantenimientosBasicosService.getChildtoFilterNidos().subscribe(response=>{
      if(response){
        this.showmenu=false;
        this.Nidos=[];
        this.Nidos=response;
        this.dataSource.data = SIDEBAR_MENU_LINKS;
        this.Nidos.forEach(element => {
          this.dataSource.data.forEach(elementX=>{
            if(elementX.name=='Nidos'){
              if(elementX.children){
                elementX.children=[];
              }
            }
          })
        });
        this.Nidos.forEach(element => {
          this.dataSource.data.forEach(elementX=>{
            if(elementX.name=='Nidos'){
              if(elementX.children){
                elementX.children.push({name:element.nombre,route:'consolidate/nido/'+element.id});
              }
            }
          })
        });
  setTimeout(() => {
    this.showmenu=true;
  }, 100);
      
      }
    });
  }

  ngOnInit(): void {
    this.dataSource.data = SIDEBAR_MENU_LINKS;
    this.Nidos=[
      {
        id:'1',
        nombre: 'Casa',
        saldo: 0,
        estado: true,
        color:'#FFC300'
      },
      {
        id:'2',
        nombre: 'Gastos extra',
        saldo: 0,
        estado: true,
        color:'#2874A6'
      },
      {
        id:'3',
        nombre: 'Vacaciones',
        saldo: 0,
        estado: true,
        color:'#8E44AD'
      },
      {
        id:'4',
        nombre: 'Ahorro',
        saldo: 0,
        estado: true,
        color:'#229954'
      }
    ]
    sessionStorage.setItem('Nidos', JSON.stringify(this.Nidos));
    
          this.Nidos.forEach(element => {
            this.dataSource.data.forEach(elementX=>{
              if(elementX.name=='Nidos'){
                if(elementX.children){
                  elementX.children.push({name:element.nombre,route:'consolidate/nido/'+element.id});
                }
              }
            })
          });

        this.showmenu=true;

      
      
  }

  hasChild = (_: number, node: ISideBarMenuNode) =>
    !!node.children && node.children.length > 0;
}
