<app-global-spinner></app-global-spinner>
<div id="dashboard-wrapper" class="grid grid-rows-[auto_auto_81.5vh_auto]">
  <app-header></app-header>

  <app-top-navigation-bar></app-top-navigation-bar>

  <div>
    <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>
</div>
