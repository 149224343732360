import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MantenimientosBasicosFiltroStateRepository } from 'src/app/state/mantenimientos-basicos/mantenimientos-basicos-filtros.repository.state.service';
interface movimientosData {
  id: string;
  estado: boolean;
  fecha: string;
  descripcion: string;
  importe: string;
  operation: string;
  ennido:boolean;
  nidoid: string;
}
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  dataForm: FormGroup;
  totalValue:any=0;
  constructor( private formBuilder: FormBuilder,
    private mantenimientosBasicosFiltroStateRepository: MantenimientosBasicosFiltroStateRepository<movimientosData>,
    ) {
    this.dataForm = this.formBuilder.group({
      saldo: new FormControl<string | null>(null),
      
    });
  }

  ngOnInit(): void {
    let valueshow=0;
    setTimeout(() => {
      let movimientos =
        this.mantenimientosBasicosFiltroStateRepository.getFromSessionStorage(
          'movimientos-principal'
        );
        let valuesMovimientos: any[]=[];
        if(movimientos){
          valuesMovimientos= Object.values(movimientos);
         
          valuesMovimientos.forEach(element => {
            if(element.operation=='suma'){
              valueshow=valueshow+parseFloat(element.importe);
            }
            else{
              valueshow=valueshow-parseFloat(element.importe);
            }
          });
        }
        let x=valueshow
        
        

        this.dataForm.controls['saldo'].setValue('€ '+x.toFixed(2));
    }, 500);
       
  }
}
