import { Injectable } from '@angular/core';
import { createStore, Store } from '@ngneat/elf';
import {
  addEntities,
  deleteEntities,
  setEntities,
  updateEntities,
  withEntities
} from '@ngneat/elf-entities';
import { excludeKeys, persistState, sessionStorageStrategy } from '@ngneat/elf-persist-state';

interface FilterBase {
  id: string;
}

@Injectable({
  providedIn: 'root',
})
export class MantenimientosBasicosFiltroStateRepository<T extends FilterBase> {
  nombreFormulario: string = 'filtros-mantenimientos';
  mantenimientosBasicosFiltroStore: Store;

  constructor() {
    this.mantenimientosBasicosFiltroStore = createStore(
      { name: 'mantenimientosBasicosFiltros' },
      withEntities<T>({ idKey: 'id' })
    );
  }

  get store(): Store {
    return this.mantenimientosBasicosFiltroStore;
  }

  setMantenimientosBasicosFiltros(mantenimientosBasicosFiltros: T[]): void {
    this.store.update(setEntities(mantenimientosBasicosFiltros));
  }

  addMantenimientosBasicosFiltro(mantenimientosBasicosFiltro: T): void {
    this.store.update(addEntities(mantenimientosBasicosFiltro));
  }

  updateMantenimientosBasicosFiltro(
    id: T['id'],
    mantenimientosBasicosFiltro: Partial<T[]>
  ): void {
    this.store.update(updateEntities(id, mantenimientosBasicosFiltro));
  }

  deleteMantenimientosBasicosFiltro(id: T['id']): void {
    this.store.update(deleteEntities(id));
  }

  saveToSessionStorage(filterFormId: string, filterData: T[]): void {
    this.persistLocalStorage();
    this.updateMantenimientosBasicosFiltro(filterFormId, filterData);
  }

  getFromSessionStorage(filterFormId: string): T | null {
    const item = sessionStorage.getItem(this.nombreFormulario);
    if (item) {
      const filtro = JSON.parse(item);
      return filtro.entities[filterFormId];
    }
    return null;
  }

  persistLocalStorage(): void {
    persistState(this.store, {
      key: this.nombreFormulario,
      storage: sessionStorageStrategy,
      source: () => this.mantenimientosBasicosFiltroStore.pipe(excludeKeys(['ids']))
    });
  }
}
