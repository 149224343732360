import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { STATUS_NOTIFICATION } from 'src/app/constants/user-interface/status-notification.constants';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
  standalone: true,
})
export class ErrorPageComponent implements OnInit {
  error: string | null = '';

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.error = this.activatedRoute.snapshot.queryParamMap.get('errorMessage')
      ? this.activatedRoute.snapshot.queryParamMap.get('errorMessage')
      : STATUS_NOTIFICATION.ERROR_DESCONOCIDO;
  }
}
