<nav class="bg-blue-900 px-1 py-1.5">
  <div class="flex items-center topside">
    <div class="flex flex-grow">
      <button mat-icon-button class="text-white" (click)="toggleSideNav()">
        <mat-icon *ngIf="sideNavState">menu</mat-icon>
        <mat-icon *ngIf="!sideNavState">close</mat-icon>
      </button>
    </div>
    <ul class="flex flex-grow">
      <ng-container *ngFor="let topNavigationBarItem of topNavigationBarItems">
        <li class="first: border-l-2">
          <div class="containersolgan">
            <label class="titlenest">Nest: </label>
            <label class="slogan">Divide y vencerás</label>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>
</nav>
