import { NgModule } from '@angular/core';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { SharedModule } from '../../shared/shared.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { LeftSidebarNavigationTreeComponent } from './navigation/left-sidebar-navigation-tree/left-sidebar-navigation-tree.component';
import { TopNavigationBarComponent } from './navigation/top-navigation-bar/top-navigation-bar.component';

@NgModule({
  declarations: [
    DashboardComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    TopNavigationBarComponent,
    LeftSidebarNavigationTreeComponent,
  ],
  imports: [BreadcrumbModule, SharedModule],
})
export class LayoutModule {}
