<section class="grid grid-rows-1 justify-items-center items-center">
  <header>
    <ng-container >
      <h3 class="m-0">{{confirmationDialogDataTitulo}}</h3>
      </ng-container>
  </header>

  <article class="text-center mt-1" *ngIf="showForm">
      <form [formGroup]="dataForm">
        <section class="grid grid-flow-row w-[20%]" >
          <div class="infocontainer">
            <h3 class="textTitle"> Saldos a mover</h3>
          <table class="mb-4">
            <thead>
              <th class="bg-[#ac4c3c ] rounded-tl-lg text-base text-left" style="width: 49%;">Descripción</th>
              <th class="bg-[#ac4c3c ] rounded-tr-lg text-base text-left" style="width: 21%;">Importe</th>
            </thead>
            <tbody>
              <tr *ngFor="let mov of confirmationDialogDataData">
                <td>{{mov.descripcion}}</td>
                <div class="valueField" *ngIf="confirmationDialogDataData.operation==='resta'">€ -{{mov.importe}}</div>
                <div class="valueField" *ngIf="confirmationDialogDataData.operation!=='resta'">€ +{{mov.importe}}</div>
             
              </tr>
            </tbody>
          </table>
            <div class="flex gap-4 items-baseline">
              <div class="divform">
                <mat-form-field id="estado" appearance="outline">
                  <mat-label class="text-sm font-medium text-gray-900">Nido:</mat-label>
                  <mat-select formControlName="nido">
                    <mat-option
                      *ngFor="let nido of Nidos"
                      [value]="nido"
                    >
                      {{ nido.nombre }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="flex gap-4 items-baseline">
              <div class="divform">
                <mat-form-field id="estado" appearance="outline">
                  <mat-label class="text-sm font-medium text-gray-900">Paso automático</mat-label>
                  <mat-select>
                    <mat-option
                      *ngFor="let nido of pasos"
                      [value]="nido"
                    >
                      {{ nido.nombre }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          
        </section>
      </form>
  </article>

  <div class="flex justify-center gap-4">
    <div *ngIf="confirmationDialogData.dialogTextConfirmar">
      <button
        mat-raised-button
        (click)="confirmarAccionDialog()"
        class="buttonpredeterminado"
        [disabled]="!dataForm.valid"
      >
        <span class="text-white">
          {{ confirmationDialogData.dialogTextConfirmar }}
        </span>
      </button>
    </div>
    <div *ngIf="confirmationDialogData.dialogTextCancelar">
      <button
        class="ml-4"
        color="warn"
        mat-raised-button
        (click)="cerrarDialog()"
      >
        <span class="text-white">
          {{ confirmationDialogData.dialogTextCancelar }}
        </span>
      </button>
    </div>
  </div>
</section>
