import { DOCUMENT } from '@angular/common';
import {
  HttpHeaders,
  HttpResponse
} from '@angular/common/http';
import { Component, inject, Input, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  finalize, Subject,
  takeUntil,
  throwError
} from 'rxjs';
import { HTTP_HEADERS } from 'src/app/constants/backend-web-services/http-headers';
import { ConfirmationDialogData } from 'src/app/constants/user-interface/user-interface-messages.types';
import { Filtro } from 'src/app/models/filtros/filtro.model';
import { GridMantenimientosBasicosService } from '../../pages/business-runaev/mantenimientos-basicos/grid-mantenimientos-basicos.service';
import { NotificationDialogService } from '../notification-dialog/notification-dialog.service';

@Component({
  selector: 'app-grid-file-download',
  templateUrl: './grid-file-download.component.html',
  styleUrls: ['./grid-file-download.component.scss'],
})
export class GridFileDownloadComponent implements OnDestroy {
  unsubscribe = new Subject();

  @Input() filtros!: Filtro;
  private document: Document = inject(DOCUMENT);

  private loading = new BehaviorSubject<boolean>(false);
  public loading$ = this.loading.asObservable();

  constructor(
    private gridMantenimientosBasicosService: GridMantenimientosBasicosService<any>,
    private notificationDialogService: NotificationDialogService
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe?.complete();
    this.unsubscribe?.unsubscribe();
  }

  descargarCSV(): void {
    this.loading.next(true);
    this.gridMantenimientosBasicosService
      .descargarCSV(this.filtros)
      .pipe(
        takeUntil(this.unsubscribe),
        finalize(() => this.loading.next(false)),
        catchError((error: string) => {
          return throwError(() => {
            //TODO revisar este manejo de errores, no debería ser hardcode
            if (!error.includes('Http failure response')) {
              const dialogConfiguration: ConfirmationDialogData = {
                dialogType: 'ERROR',
                dialogMessage: error,
                dialogTextConfirmar: 'Aceptar',
              };
              this.notificationDialogService.abrir(dialogConfiguration);
            }
          });
        })
      )
      .subscribe((response: HttpResponse<string>) => {
        if (response) {
          this.saveAs(response);
        }
      });
  }

  saveAs(response: HttpResponse<string>): void {
    const blob = new Blob([response.body as BlobPart], {
      type: HTTP_HEADERS.RESPONSE_TYPE_CSV,
    });
    const downloadLink: HTMLAnchorElement = this.document.createElement('a');
    const objectUrl = URL.createObjectURL(blob);
    downloadLink.href = objectUrl;
    downloadLink.download = this.getFileName(response.headers);
    downloadLink.click();
    URL.revokeObjectURL(objectUrl);
  }

  getFileName(headers: HttpHeaders): string {
    let fileName = '';
    const contentDispositionHeader = headers.get('content-disposition');
    if (contentDispositionHeader) {
      const contentDispositionHeaderResult = contentDispositionHeader
        .split(';')[1]
        .trim()
        .split('=')[1];
      fileName = contentDispositionHeaderResult.replace(/"/g, '');
    }
    return fileName ? fileName : 'Datos Mantenimiento Básico';
  }
}
